import { FormControl, Grid, TextField, Tooltip } from "@mui/material";
import { NumericFormat } from 'react-number-format';
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { INumberFormControlProperty } from "@definitions/index";
import { FieldError } from "@components/FieldError";
import { flexCenterProps, horizontalFormControlGridLeftStyle, paddingX1Props, requiredLabelStyle } from "@styles/global-css-class";
import { StyledFormLabel } from "..";
function NewNumberFormControl({
  childKey,
  label,
  value,
  name,
  errorName,
  defaultValue,
  placeHolder,
  isRequired,
  isDisabled,
  isHidden = false,
  onChange,
  formState,
  decimalDigits,
  xsFormControl = 12,
  mdFormControl = 12,
  xsLabel = 0,
  xsInput,
  textAlign,
  textErrorAlign,
  itemSx,
  textSx,
  isHideLabel = true,
  labelToolTip,
  min,
  max,
  endAdornmentLabel,
  decimalSeparator = ".",
  thousandSeparator,
  allowLeadingZeros,
  allowNegative,
  fixedDecimalScale,
  isExpanded,
  isPercentNumber,
  ...props
}: Readonly<INumberFormControlProperty>) {
  const decimalPlaceholder = decimalDigits && decimalDigits > 0 ? "0." + "0".repeat(decimalDigits) : "0";
  const inputRef = useRef<HTMLInputElement>(null);
  const [valueInput, setValueInput] = useState(value ?? defaultValue ?? '');
  const validPercentage = useCallback((value: number) => {
    return value > 100 ? 100 : value;
  }, []);
  useEffect(() => {
    if (isExpanded && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [isExpanded, inputRef.current]);
  return <Grid container item xs={xsFormControl} md={mdFormControl} sx={itemSx ?? {
    ...paddingX1Props,
    alignContent: 'flex-start'
  }} data-sentry-element="Grid" data-sentry-component="NewNumberFormControl" data-sentry-source-file="NewNumberFormControl.tsx">
		<FormControl key={`${childKey}-form-control`} fullWidth margin='normal' {...props} data-sentry-element="FormControl" data-sentry-source-file="NewNumberFormControl.tsx">
			<Grid container item sx={flexCenterProps} data-sentry-element="Grid" data-sentry-source-file="NewNumberFormControl.tsx">
				{!isHideLabel && <Grid item xs={xsLabel} sx={itemSx ?? horizontalFormControlGridLeftStyle}>
					<StyledFormLabel aria-describedby={name} hidden={isHidden} error={formState?.errors[name] !== undefined} sx={textSx ?? {
            ...requiredLabelStyle,
            textAlign: textAlign ?? "right"
          }}>
						{label}
					</StyledFormLabel>
				</Grid>}
				<Grid container item xs={xsInput ?? (xsLabel === 12 ? 12 : 12 - xsLabel)} sx={{
          position: "relative"
        }} data-sentry-element="Grid" data-sentry-source-file="NewNumberFormControl.tsx">
					{labelToolTip ? <Tooltip title={labelToolTip}>
						<NumericFormat key={`${childKey}-form-control-input`} id={name} name={name} placeholder={placeHolder ?? decimalPlaceholder} disabled={isDisabled ?? false} hidden={isHidden} required={isRequired} value={valueInput} onFocus={e => {
              const parseValue = parseFloat(e.target.value);
              if (parseValue === 0) {
                setValueInput("");
              }
            }} onChange={e => {
              setValueInput(e.target.value);
            }} onBlur={e => {
              const floatValue = parseFloat(e.target.value?.replaceAll(",", ""));
              if (endAdornmentLabel?.trim().startsWith("%")) {
                return onChange(isNaN(floatValue) ? null : validPercentage(floatValue));
              }
              return onChange(isNaN(floatValue) ? null : floatValue);
            }} customInput={TextField} thousandSeparator={thousandSeparator} allowLeadingZeros={allowLeadingZeros} allowNegative={allowNegative} readOnly={isDisabled} min={min} max={max} error={formState?.errors[errorName ?? name] !== undefined} decimalSeparator={decimalSeparator} thousandsGroupStyle="thousand" decimalScale={decimalDigits} fixedDecimalScale={fixedDecimalScale} valueIsNumericString label={label} suffix={endAdornmentLabel} sx={{
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                display: 'none'
              },
              width: "100%"
            }} inputProps={{
              ref: inputRef
            }} />
					</Tooltip> : <NumericFormat key={`${childKey}-form-control-input`} id={name} name={name} placeholder={placeHolder ?? decimalPlaceholder} disabled={isDisabled ?? false} hidden={isHidden} required={isRequired} value={value ?? defaultValue ?? ""} onFocus={e => {
            const parseValue = parseFloat(e.target.value);
            if (parseValue === 0) {
              setValueInput("");
            }
          }} onChange={e => {
            setValueInput(e.target.value);
          }} onBlur={e => {
            const floatValue = parseFloat(e.target.value?.replaceAll(",", ""));
            if (endAdornmentLabel?.trim().startsWith("%") || isPercentNumber) {
              return onChange(isNaN(floatValue) ? null : validPercentage(floatValue));
            }
            return onChange(isNaN(floatValue) ? null : floatValue);
          }} customInput={TextField} thousandSeparator={thousandSeparator} allowLeadingZeros={allowLeadingZeros} allowNegative={allowNegative} readOnly={isDisabled} min={min} max={max} error={formState?.errors[errorName ?? name] !== undefined} decimalSeparator={decimalSeparator} thousandsGroupStyle="thousand" decimalScale={decimalDigits} fixedDecimalScale={fixedDecimalScale} valueIsNumericString label={label} suffix={endAdornmentLabel} sx={{
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
              display: 'none'
            },
            width: "100%"
          }} inputProps={{
            ref: inputRef
          }} />}
				</Grid>
			</Grid>
			{formState && <FieldError key={`${childKey}-form-control-error`} formState={formState} name={name} textAlign={textErrorAlign} />}
		</FormControl>
	</Grid>;
}
export default memo(NewNumberFormControl);
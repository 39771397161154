import React, { memo, useEffect, useRef } from "react";
import { Checkbox, FormControl, FormControlLabel, Grid } from "@mui/material";
import { horizontalFormControlGridRightStyle, paddingX1Props } from "@styles/global-css-class";
import { ICheckboxFormControlProperty } from "@definitions/index";
import { FieldError } from "@components/FieldError";
function NewCheckboxFormControl({
  childKey,
  label,
  labelPlacement,
  value,
  name,
  defaultValue,
  placeHolder,
  isRequired,
  isDisabled,
  isHidden = false,
  onChange,
  formState,
  xsFormControl = 12,
  mdFormControl = 12,
  textAlign,
  textErrorAlign,
  itemSx,
  textSx,
  isShowCheckboxLabel = true,
  checkBoxSx,
  isExpanded,
  isHideLabel,
  isShowRequiredAsterisk,
  ...props
}: Readonly<ICheckboxFormControlProperty>) {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (isExpanded && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [isExpanded, inputRef.current]);
  return <Grid container item xs={xsFormControl} md={mdFormControl} sx={itemSx ?? {
    ...paddingX1Props,
    py: "13px",
    alignContent: 'center'
  }} data-sentry-element="Grid" data-sentry-component="NewCheckboxFormControl" data-sentry-source-file="NewCheckboxFormControl.tsx">
		<FormControl key={`${childKey}-form-control`} fullWidth margin='dense' {...props} data-sentry-element="FormControl" data-sentry-source-file="NewCheckboxFormControl.tsx">
			<Grid container data-sentry-element="Grid" data-sentry-source-file="NewCheckboxFormControl.tsx">
				<Grid item sx={checkBoxSx ?? itemSx ?? horizontalFormControlGridRightStyle} data-sentry-element="Grid" data-sentry-source-file="NewCheckboxFormControl.tsx">
					<FormControlLabel key={`${childKey}-form-control-label`} labelPlacement={labelPlacement} control={<Checkbox id={name} name={name} key={`${childKey}-form-control-${name}`} checked={value ?? defaultValue ?? false} onChange={e => {
            onChange(e.target.checked);
          }} inputRef={inputRef} hidden={isHidden} disabled={isDisabled ?? false} color={formState?.errors[name] !== undefined ? "error" : "default"} size="small" value={isShowCheckboxLabel && label} />} label={!isHideLabel && `${label} ${isRequired && isShowRequiredAsterisk ? '*' : ''}`} data-sentry-element="FormControlLabel" data-sentry-source-file="NewCheckboxFormControl.tsx" />
				</Grid>
				{formState && <FieldError key={`${childKey}-form-control-error`} formState={formState} name={name} textAlign={textErrorAlign} />}
			</Grid>
		</FormControl>
	</Grid>;
}
export default memo(NewCheckboxFormControl);
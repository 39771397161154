import { FormControl, FormControlLabel, Grid } from "@mui/material";
import { memo, useEffect, useRef } from "react";
import { ToggleSwitch } from "@components/Common";
import { IFormControlProperty } from "@definitions/index";
import { FieldError } from "@components/FieldError";
import { paddingX1Props } from "@styles/global-css-class";
const NewToggleFormControl = ({
  childKey,
  label,
  labelPlacement = "end",
  name,
  value,
  defaultValue,
  onChange,
  isRequired,
  isDisabled,
  isHidden,
  formState,
  xsFormControl = 12,
  mdFormControl = 12,
  textAlign,
  textErrorAlign,
  itemSx,
  isExpanded,
  isShowRequiredAsterisk = true,
  isHideLabel,
  ...props
}: IFormControlProperty) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (isExpanded && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [isExpanded, inputRef.current]);
  return <Grid container item xs={xsFormControl} md={mdFormControl} sx={itemSx ?? {
    ...paddingX1Props,
    alignContent: 'flex-start'
  }} data-sentry-element="Grid" data-sentry-component="NewToggleFormControl" data-sentry-source-file="NewToggleFormControl.tsx">
        <FormControl fullWidth margin='normal' sx={{
      justifyContent: "center",
      paddingY: 0.86
    }} {...props} data-sentry-element="FormControl" data-sentry-source-file="NewToggleFormControl.tsx">
            <FormControlLabel id={`${name}-label`} disabled={isDisabled} hidden={isHidden} required={isRequired} labelPlacement={labelPlacement} control={<ToggleSwitch id={`${name}-switch`} name={name} inputRef={inputRef} checked={value ?? defaultValue ?? false} value={name} onChange={(e: any, checked: boolean) => {
        onChange(checked);
      }} />} label={!isHideLabel && `${label} ${isRequired && isShowRequiredAsterisk ? '*' : ''}`} sx={{
        "& .MuiTypography-root": {
          pl: 1
        },
        p: "8px"
      }} data-sentry-element="FormControlLabel" data-sentry-source-file="NewToggleFormControl.tsx" />
            {formState && <FieldError formState={formState} name={name} textAlign={textErrorAlign} />}
        </FormControl>
    </Grid>;
};
export default memo(NewToggleFormControl);
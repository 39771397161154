import { FormControl, Grid, Select, MenuItem, InputLabel, Box, Checkbox, IconButton, InputAdornment, ListSubheader, TextField, Typography, SelectChangeEvent } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { paddingX1Props } from "@styles/global-css-class";
import { FieldError } from "@components/FieldError";
import { IMultipleSelectFormControlProperty, ListItem, SELECT_ALL_VALUE } from "@definitions/index";
import { colors } from "@components/ThemeRegistry/theme";
function NewMultipleSelectFormControl({
  childKey,
  label,
  name,
  value,
  defaultValue,
  onChange,
  isRequired,
  isDisabled,
  isHidden,
  listValues,
  placeHolder,
  formState,
  zIndex,
  xsFormControl = 12,
  mdFormControl = 12,
  xsLabel = 6,
  xsInput,
  textAlign,
  textErrorAlign,
  itemSx,
  textSx,
  errorName,
  isExpanded,
  ...props
}: Readonly<IMultipleSelectFormControlProperty>) {
  const [valueFilter, setValueFilter] = useState("");
  const [listItems, setListItems] = useState<ListItem[]>(listValues);
  const splitValue = typeof value === "string" ? value.split(",") : value;
  const [selectedValues, setSelectedValues] = useState<string[]>(splitValue ?? []);
  const renderSelectValues = (selectedValues: string[]) => {
    if (!selectedValues || selectedValues.length == 0) {
      return '';
    }
    const hasAllValue = selectedValues.includes(SELECT_ALL_VALUE);
    //Remove select all value from selected values
    const itemCount = hasAllValue ? selectedValues.length - 1 : selectedValues.length;
    if (itemCount == 0) {
      return '';
    }
    const styles = {
      textAlign: "left",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap"
    };
    if (itemCount === listValues?.length) {
      return <Box sx={styles}>All</Box>;
    }
    return <Box sx={styles} data-sentry-element="Box" data-sentry-component="renderSelectValues" data-sentry-source-file="NewMultipleSelectFormControl.tsx">{selectedValues.join(", ")}</Box>;
  };
  const handleValueChange = (event: SelectChangeEvent<string | string[]>) => {
    const value = event.target.value;
    if (value[value.length - 1] == SELECT_ALL_VALUE) {
      setSelectedValues(selectedValues.length === listItems.length ? [] : listItems.map(v => v.value.toString()));
      return;
    }
    setSelectedValues(typeof value === "string" ? value.split(",") : value);
  };
  const getFilteredOptions = (filterText: string) => {
    if (!filterText) {
      return listValues || [];
    }
    filterText = filterText.toLowerCase();
    return listValues.filter((option: any) => {
      return option.value?.toLowerCase().includes(filterText) || option.name?.toLowerCase().includes(filterText);
    });
  };
  const handleFilterChange = (event: any) => {
    setValueFilter(event.target.value);
    setListItems(getFilteredOptions(event.target.value));
  };
  const handleClearFilter = () => {
    setValueFilter('');
    setListItems(getFilteredOptions(''));
  };
  useEffect(() => {
    onChange(selectedValues);
  }, [selectedValues]);
  return <Grid container item xs={xsFormControl} md={mdFormControl} sx={itemSx ?? {
    ...paddingX1Props,
    alignContent: 'flex-start'
  }} data-sentry-element="Grid" data-sentry-component="NewMultipleSelectFormControl" data-sentry-source-file="NewMultipleSelectFormControl.tsx">
		<FormControl key={`${childKey}-form-control`} fullWidth margin='normal' {...props} required={isRequired} error={formState?.errors[name] !== undefined} data-sentry-element="FormControl" data-sentry-source-file="NewMultipleSelectFormControl.tsx">
			<InputLabel id={name} key={`${childKey}-form-control-input-label`} data-sentry-element="InputLabel" data-sentry-source-file="NewMultipleSelectFormControl.tsx">
				{label}
			</InputLabel>
			{<Select key={`${childKey}-form-control-select`} labelId={`${name}-label`} label={label} multiple value={selectedValues ?? []} onChange={handleValueChange} renderValue={renderSelectValues} onClose={() => handleClearFilter} MenuProps={{
        autoFocus: false,
        style: {
          zIndex: zIndex ?? 0 + 2
        },
        PaperProps: {
          sx: {
            maxHeight: "20vh",
            width: 200,
            "@media screen and (max-width: 1366px) and (max-height: 768px)": {
              maxHeight: "15vh"
            }
          }
        }
      }}>
					<ListSubheader sx={{
          height: '40px'
        }}>
						<TextField value={valueFilter} size="small"
          // Autofocus on textfield
          autoFocus placeholder="Type to search..." fullWidth InputProps={{
            endAdornment: <InputAdornment position="end">
										<IconButton aria-label="clear search" onClick={handleClearFilter}>
											x
										</IconButton>
									</InputAdornment>
          }} onChange={handleFilterChange} onKeyDown={e => {
            if (e.key !== "Escape") {
              // Prevents auto selecting item while typing (default Select behavior)
              e.stopPropagation();
            }
          }} />
					</ListSubheader>

					<MenuItem key="select-all" value={SELECT_ALL_VALUE} style={{
          borderBottom: "2px solid #e0e0e0"
        }}>
						<Checkbox sx={{
            color: colors.slate,
            "&.Mui-checked": {
              color: colors.slate
            }
          }} checked={listItems.length === selectedValues.length} />
						Select All
					</MenuItem>

					{listItems?.map((v, index) => <MenuItem key={`${v.value}-${index}-menu-item`} value={`${v.value}`}>
							<Checkbox key={`${v.value}-${index}-chk`} sx={{
            color: colors.slate,
            "&.Mui-checked": {
              color: colors.slate
            }
          }} checked={selectedValues.indexOf(`${v.value}`) > -1} />
							<Box key={`${v.value}-${index}-name`} sx={{
            overflow: "hidden"
          }}>
								<Typography sx={{
              wordWrap: "break-word",
              whiteSpace: "normal",
              overflowWrap: "break-word"
            }}>
									{v.name}
								</Typography>
							</Box>
						</MenuItem>)}
				</Select>}
			{formState && <FieldError key={`${childKey}-form-control-error`} formState={formState} name={errorName ?? name} textAlign={textErrorAlign} />}
		</FormControl>
	</Grid>;
}
export default memo(NewMultipleSelectFormControl);
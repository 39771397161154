import { FormControl, Grid } from "@mui/material";
import React, { memo } from "react";
import { IFormControlProperty } from "@definitions/index";
import { paddingX1Props } from "@styles/global-css-class";
function EmptyFormControl({
  xsFormControl,
  mdFormControl,
  itemSx
}: Readonly<IFormControlProperty>) {
  return <Grid container item xs={xsFormControl} md={mdFormControl} sx={itemSx ?? {
    ...paddingX1Props
  }} data-sentry-element="Grid" data-sentry-component="EmptyFormControl" data-sentry-source-file="EmptyFormControl.tsx">
		<FormControl fullWidth margin='normal' sx={{
      height: "56px"
    }} data-sentry-element="FormControl" data-sentry-source-file="EmptyFormControl.tsx">
		</FormControl>
	</Grid>;
}
export default memo(EmptyFormControl);
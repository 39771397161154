import { FormControl, Grid, Select, MenuItem, InputLabel, Tooltip, Typography } from "@mui/material";
import React, { memo, useRef, useEffect } from "react";
import { flexCenterProps, horizontalFormControlGridLeftStyle, menuItemStyle, paddingX1Props, requiredLabelStyle, selectMenuPropStyle } from "@styles/global-css-class";
import { FieldError } from "@components/FieldError";
import { IDropdownFormControlProperty } from "@definitions/index";
import { StyledFormLabel } from "..";
function NewDropdownFormControl({
  childKey,
  label,
  name,
  value,
  defaultValue,
  onChange,
  isRequired,
  isDisabled,
  isHidden,
  showSelect,
  listValues,
  placeHolder,
  formState,
  zIndex,
  xsFormControl = 12,
  mdFormControl = 12,
  xsLabel = 0,
  xsInput,
  textAlign,
  textErrorAlign,
  itemSx,
  textSx,
  errorName,
  isExpanded,
  allowUndefinedDefaultSelect = false,
  isDisableDefaultSelect,
  inputSx,
  isLabelToolTip,
  labelToolTip,
  isHideLabel = true,
  valueDefaultSelect,
  isShowRequiredAsterisk = true,
  ...props
}: Readonly<IDropdownFormControlProperty>) {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (isExpanded && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [isExpanded, inputRef.current]);
  return <Grid container item xs={xsFormControl} md={mdFormControl} sx={itemSx ?? {
    ...paddingX1Props,
    alignContent: 'flex-start'
  }} data-sentry-element="Grid" data-sentry-component="NewDropdownFormControl" data-sentry-source-file="NewDropdownFormControl.tsx">
		<FormControl key={`${childKey}-form-control`} fullWidth margin='normal' {...props} data-sentry-element="FormControl" data-sentry-source-file="NewDropdownFormControl.tsx">
			<Grid container item sx={flexCenterProps} data-sentry-element="Grid" data-sentry-source-file="NewDropdownFormControl.tsx">
				{!isHideLabel && <Grid item xs={xsLabel} sx={itemSx ?? horizontalFormControlGridLeftStyle}>
					<StyledFormLabel aria-describedby={name} hidden={isHidden} error={formState?.errors[errorName ?? name] !== undefined} sx={textSx ?? {
            ...requiredLabelStyle,
            textAlign: textAlign ?? "right"
          }}>
						{label}
						{isRequired && isShowRequiredAsterisk && ' *'}
					</StyledFormLabel>
				</Grid>}
				<Grid container item xs={xsInput ?? (xsLabel === 12 ? 12 : 12 - xsLabel)} sx={{
          position: "relative"
        }} data-sentry-element="Grid" data-sentry-source-file="NewDropdownFormControl.tsx">
					{isHideLabel && <InputLabel key={`${childKey}-form-control-input-label`} id={name} sx={textSx ?? {}} error={formState?.errors[name] !== undefined}>
						{label}
						{isRequired && ' *'}
					</InputLabel>}
					<Select key={`${childKey}-form-control-select`} labelId={isHideLabel ? name : undefined} id={name} name={name} label={isHideLabel ? `${label}${isRequired && isShowRequiredAsterisk ? ' *' : ''} ` : undefined} required={isRequired} hidden={isHidden} value={value ?? defaultValue ?? ' '} error={formState?.errors[errorName ?? name] !== undefined} inputProps={{
            ref: inputRef
          }} onChange={e => {
            onChange(e.target.value == ' ' ? null : e.target.value);
          }} sx={inputSx ?? {
            width: "100%"
          }} disabled={isDisabled} MenuProps={selectMenuPropStyle(zIndex ?? 99999)} data-sentry-element="Select" data-sentry-source-file="NewDropdownFormControl.tsx">
						{showSelect && <MenuItem disabled={isDisableDefaultSelect} sx={{
              ...menuItemStyle,
              fontStyle: "italic"
            }} key={`${childKey}-9999-null`} value={allowUndefinedDefaultSelect ? undefined : valueDefaultSelect ?? ' '}>
								{placeHolder ?? `Select ${label}`}
							</MenuItem>}
						{listValues?.map((option, idx) => {
              idx += 1;
              return typeof option === "object" ? <MenuItem sx={menuItemStyle} key={`${childKey}-${idx}-${option.value}`} value={option.value}>
								{option.name}
							</MenuItem> : <MenuItem sx={menuItemStyle} key={`${childKey}-${idx}-${option}`} value={option}>
								{option}
							</MenuItem>;
            })}
					</Select>
				</Grid>
			</Grid>
			{formState && <FieldError key={`${childKey}-form-control-error`} formState={formState} name={errorName ?? name} textAlign={textErrorAlign} />}
		</FormControl>
	</Grid>;
}
export default memo(NewDropdownFormControl);
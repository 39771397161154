import { FormControl, Grid, InputAdornment, TextField, Tooltip } from "@mui/material";
import React, { memo, useCallback, useEffect, useRef, useState, KeyboardEvent } from "react";
import { FieldError } from "@components/FieldError";
import { ITextFormControlProperty } from "@definitions/definitions";
import { customDisabledOutlineInput, flexCenterProps, horizontalFormControlGridLeftStyle, paddingX1Props, requiredLabelStyle } from "@styles/global-css-class";
import { StyledFormLabel } from "@components/Common";
import { EDITING_KEYS, NAVIGATION_KEYS, NUMBER_VALID_KEYS } from "@constants/common";
function NewOnlyTextFormControl({
  childKey,
  label,
  value,
  name,
  defaultValue,
  placeHolder,
  isRequired,
  isDisabled,
  isHidden = false,
  onChange,
  formState,
  xsFormControl = 12,
  mdFormControl = 12,
  xsLabel = 0,
  xsInput,
  textAlign,
  textErrorAlign,
  itemSx,
  textSx,
  isExpanded,
  isHideLabel = true,
  labelToolTip,
  hasEndAdornment,
  endAdornmentLabel,
  itemFocus,
  onBlur,
  onlyNumber,
  formatValue,
  ...props
}: Readonly<ITextFormControlProperty>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [valueInput, setValueInput] = useState(value ?? defaultValue ?? '');
  useEffect(() => {
    if (itemFocus) {
      inputRef.current?.focus();
    }
  }, [itemFocus]);
  useEffect(() => {
    if (isExpanded && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [isExpanded, inputRef.current]);
  const handleOnBlur = useCallback((event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    if (onBlur) {
      onChange(event.target.value);
      return onBlur(event.target.value);
    }
    return onChange(event.target.value);
  }, [onBlur, onChange]);
  const handleNumberKey = useCallback((e: KeyboardEvent) => {
    const numValidKeys = [...NUMBER_VALID_KEYS, ...NAVIGATION_KEYS, ...EDITING_KEYS];
    if (onlyNumber && !numValidKeys.includes(e.key)) {
      return e.preventDefault();
    }
  }, [onlyNumber]);
  return <Grid container item xs={xsFormControl} md={mdFormControl} sx={itemSx ?? {
    ...paddingX1Props,
    alignContent: 'flex-start'
  }} data-sentry-element="Grid" data-sentry-component="NewOnlyTextFormControl" data-sentry-source-file="NewOnlyTextFormControl.tsx">
		<FormControl key={`${childKey}-form-control`} fullWidth margin='normal' {...props} data-sentry-element="FormControl" data-sentry-source-file="NewOnlyTextFormControl.tsx">
			<Grid container item sx={flexCenterProps} data-sentry-element="Grid" data-sentry-source-file="NewOnlyTextFormControl.tsx">
				{!isHideLabel && <Grid item xs={xsLabel} sx={itemSx ?? horizontalFormControlGridLeftStyle}>
					<StyledFormLabel aria-describedby={name} hidden={isHidden} error={formState?.errors[name] !== undefined} sx={textSx ?? {
            ...requiredLabelStyle,
            textAlign: textAlign ?? "right"
          }}>
						{label}
					</StyledFormLabel>
				</Grid>}
				<Grid container item xs={xsInput ?? (xsLabel === 12 ? 12 : 12 - xsLabel)} sx={{
          position: "relative"
        }} data-sentry-element="Grid" data-sentry-source-file="NewOnlyTextFormControl.tsx">
					{labelToolTip ? <Tooltip title={labelToolTip}>
						<TextField type='text' id={name} key={`${childKey}-input`} name={name} placeholder={placeHolder} error={formState?.errors[name] !== undefined} inputRef={inputRef} disabled={isDisabled ?? false} hidden={isHidden} required={isRequired} label={isHideLabel ? label : undefined} spellCheck value={valueInput} onChange={e => {
              setValueInput(formatValue ? formatValue(e.target.value) : e.target.value);
            }} onKeyDown={handleNumberKey} onBlur={handleOnBlur} sx={{
              width: "100%",
              ...customDisabledOutlineInput
            }} InputProps={{
              endAdornment: hasEndAdornment ? <InputAdornment position="end">{endAdornmentLabel}</InputAdornment> : null
            }} />
					</Tooltip> : <TextField type='text' id={name} key={`${childKey}-input`} name={name} placeholder={placeHolder} error={formState?.errors[name] !== undefined} inputProps={{
            ref: inputRef
          }} disabled={isDisabled ?? false} hidden={isHidden} required={isRequired} label={isHideLabel ? label : undefined} spellCheck value={valueInput} onChange={e => {
            setValueInput(formatValue ? formatValue(e.target.value) : e.target.value);
          }} onKeyDown={handleNumberKey} onBlur={handleOnBlur} sx={{
            width: "100%",
            ...customDisabledOutlineInput
          }} InputProps={{
            endAdornment: hasEndAdornment ? <InputAdornment position="end">{endAdornmentLabel}</InputAdornment> : null
          }} />}
				</Grid>
			</Grid>
			{formState && <FieldError key={`${childKey}-form-control-error`} formState={formState} name={name} textAlign={textErrorAlign} />}
		</FormControl>
	</Grid>;
}
export default memo(NewOnlyTextFormControl);
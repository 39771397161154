import { FormControl, Grid, InputAdornment, TextField, Tooltip } from "@mui/material";
import React, { ChangeEvent, memo, useCallback, useEffect, useRef, KeyboardEvent } from "react";
import { FieldError } from "@components/FieldError";
import { DOT, EDITING_KEYS, NAVIGATION_KEYS, NUMBER_VALID_KEYS } from "@constants/common";
import { ITextFormControlProperty } from "@definitions/definitions";
import { customDisabledOutlineInput, flexCenterProps, horizontalFormControlGridLeftStyle, paddingX1Props, requiredLabelStyle } from "@styles/global-css-class";
import { StyledFormLabel } from "@components/Common";
function NewTextFormControl({
  childKey,
  label,
  value,
  name,
  defaultValue,
  placeHolder,
  onlyNumber,
  isRequired,
  isDisabled,
  isHidden = false,
  onChange,
  onBlur,
  formState,
  isNumberInput,
  decimalDigits = 0,
  xsFormControl = 12,
  mdFormControl = 12,
  xsLabel = 0,
  xsInput,
  textAlign,
  textErrorAlign,
  itemSx,
  textSx,
  isExpanded,
  isHideLabel = true,
  labelToolTip,
  hasEndAdornment,
  endAdornmentLabel,
  itemFocus,
  ...props
}: Readonly<ITextFormControlProperty>) {
  const filterNonDigitAndDecimalPart = (inputValue: string) => {
    inputValue = `${inputValue}`;
    const dotIndex = inputValue.indexOf(".");
    if (dotIndex != undefined && dotIndex !== -1) {
      const decimalPart = inputValue.substring(dotIndex + 1);
      if (decimalPart.length > decimalDigits) {
        inputValue = inputValue.substring(0, dotIndex + decimalDigits + 1);
      }
    }
    return inputValue;
  };
  const formatDecimalNumberStringValue = (filteredValue: string) => {
    let formatValue = parseFloat(filteredValue);
    if (decimalDigits > 0) {
      return formatValue.toFixed(decimalDigits);
    }
    return formatValue.toFixed(0);
  };
  const handleInputRender = (value: string | undefined) => {
    if ((isNumberInput || onlyNumber) && value !== undefined) {
      const filteredValue = filterNonDigitAndDecimalPart(value);
      if (decimalDigits > 0) {
        const formatValue = formatDecimalNumberStringValue(filteredValue);
        return formatValue ?? 0 .toFixed(decimalDigits);
      }
      return filteredValue;
    }
    return value ?? '';
  };
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;
    if (isNumberInput || onlyNumber) {
      if (decimalDigits === 0) {
        inputValue = filterNonDigitAndDecimalPart(inputValue);
        onChange(inputValue);
        return;
      }
      onChange(Number(inputValue));
      return;
    }
    onChange(inputValue);
  };
  const handleInputBlur = (value: string | undefined) => {
    if (isNumberInput || onlyNumber) {
      value = value !== undefined && value !== "" && value !== null ? value : "0";
      const filteredValue = filterNonDigitAndDecimalPart(value);
      if (decimalDigits > 0) {
        const formatValue = formatDecimalNumberStringValue(filteredValue);
        if (formatValue !== "") {
          onChange(formatValue);
        }
      }
    }
    if (onBlur !== undefined) {
      onBlur(value);
    }
  };
  const handleNumberKey = useCallback((e: KeyboardEvent) => {
    const numValidKeys = [...NUMBER_VALID_KEYS, DOT, ...NAVIGATION_KEYS, ...EDITING_KEYS];
    if ((isNumberInput || onlyNumber) && !numValidKeys.includes(e.key)) {
      return e.preventDefault();
    }
  }, [isNumberInput, onlyNumber]);
  const decimalPlaceholder = isNumberInput || onlyNumber ? 0 .toFixed(decimalDigits ?? 0) : "";
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (itemFocus) {
      inputRef.current?.focus();
    }
  }, [itemFocus]);
  useEffect(() => {
    if (isExpanded && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [isExpanded, inputRef.current]);
  return <Grid container item xs={xsFormControl} md={mdFormControl} sx={itemSx ?? {
    ...paddingX1Props,
    alignContent: 'flex-start'
  }} data-sentry-element="Grid" data-sentry-component="NewTextFormControl" data-sentry-source-file="NewTextFormControl.tsx">
		<FormControl key={`${childKey}-form-control`} fullWidth margin='normal' {...props} data-sentry-element="FormControl" data-sentry-source-file="NewTextFormControl.tsx">
			<Grid container item sx={flexCenterProps} data-sentry-element="Grid" data-sentry-source-file="NewTextFormControl.tsx">
				{!isHideLabel && <Grid item xs={xsLabel} sx={itemSx ?? horizontalFormControlGridLeftStyle}>
					<StyledFormLabel aria-describedby={name} hidden={isHidden} error={formState?.errors[name] !== undefined} sx={textSx ?? {
            ...requiredLabelStyle,
            textAlign: textAlign ?? "right"
          }}>
						{label}
					</StyledFormLabel>
				</Grid>}
				<Grid container item xs={xsInput ?? (xsLabel === 12 ? 12 : 12 - xsLabel)} sx={{
          position: "relative"
        }} data-sentry-element="Grid" data-sentry-source-file="NewTextFormControl.tsx">
					{labelToolTip ? <Tooltip title={labelToolTip}>
						<TextField type='text' id={name} key={`${childKey}-input`} name={name} placeholder={placeHolder ?? decimalPlaceholder} error={formState?.errors[name] !== undefined} inputRef={inputRef} disabled={isDisabled ?? false} hidden={isHidden} required={isRequired} label={isHideLabel ? label : undefined} spellCheck value={handleInputRender(value ?? defaultValue)} onChange={handleInputChange} onBlur={e => {
              handleInputBlur(value);
            }} onKeyDown={e => handleNumberKey(e)} sx={{
              width: "100%",
              ...customDisabledOutlineInput
            }} InputProps={{
              endAdornment: hasEndAdornment ? <InputAdornment position="end">{endAdornmentLabel}</InputAdornment> : null
            }} />
					</Tooltip> : <TextField type='text' id={name} key={`${childKey}-input`} name={name} placeholder={placeHolder ?? decimalPlaceholder} error={formState?.errors[name] !== undefined} inputProps={{
            ref: inputRef
          }} disabled={isDisabled ?? false} hidden={isHidden} required={isRequired} label={isHideLabel ? label : undefined} spellCheck value={handleInputRender(value ?? defaultValue)} onChange={handleInputChange} onBlur={e => {
            handleInputBlur(value);
          }} onKeyDown={e => handleNumberKey(e)} sx={{
            width: "100%",
            ...customDisabledOutlineInput
          }} InputProps={{
            endAdornment: hasEndAdornment ? <InputAdornment position="end">{endAdornmentLabel}</InputAdornment> : null
          }} />}
				</Grid>
			</Grid>
			{formState && <FieldError key={`${childKey}-form-control-error`} formState={formState} name={name} textAlign={textErrorAlign} />}
		</FormControl>
	</Grid>;
}
export default memo(NewTextFormControl);
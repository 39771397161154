import { Box, FormControl, Grid, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { memo, useEffect, useRef, useState } from "react";
import { StyledFormLabel } from "@components/Common";
import { FieldError } from "@components/FieldError";
import { IDateFormControlProperty } from "@definitions/index";
import { flexCenterProps, horizontalFormControlGridLeftStyle, paddingX1Props, requiredLabelStyle } from "@styles/global-css-class";
import { DatePicker } from "@mui/x-date-pickers";
function NewSeparateLabelDatePickerFormControl({
  childKey,
  label,
  name,
  value,
  defaultValue,
  onChange,
  isRequired,
  isDisabled,
  isHidden,
  formState,
  xsFormControl = 12,
  mdFormControl = 12,
  xsLabel = 6,
  xsInput,
  textAlign,
  textErrorAlign,
  itemSx,
  textSx,
  zIndex,
  isExpanded,
  formatDate,
  isShowSeparateLabel,
  separateLabel,
  datePickerViews,
  isHideHeader,
  openTo,
  isHideLabel,
  ...props
}: Readonly<IDateFormControlProperty>) {
  const [dateValue, setDateValue] = useState<Dayjs | null>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [cleared, setCleared] = useState<boolean>(false);
  const handleValue = (dateValue: Dayjs | null) => {
    if (dateValue !== null && !dateValue.toString().startsWith('0001-01-01')) {
      return dateValue;
    }
    return null;
  };
  useEffect(() => {
    if (value !== null && value !== undefined && !value.toString().startsWith('0001-01-01')) {
      setDateValue(dayjs(value));
    } else {
      setDateValue(null);
    }
  }, [value]);
  useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 300);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared]);
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (isExpanded && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [isExpanded, inputRef.current]);
  return <Grid container item xs={xsFormControl} md={mdFormControl} sx={itemSx ?? {
    ...paddingX1Props,
    alignContent: 'flex-start'
  }} data-sentry-element="Grid" data-sentry-component="NewSeparateLabelDatePickerFormControl" data-sentry-source-file="NewSeparateLabelDatePickerFormControl.tsx">
		<FormControl key={`${childKey}-form-control`} fullWidth margin='normal' {...props} data-sentry-element="FormControl" data-sentry-source-file="NewSeparateLabelDatePickerFormControl.tsx">
			<Grid container item sx={flexCenterProps} data-sentry-element="Grid" data-sentry-source-file="NewSeparateLabelDatePickerFormControl.tsx">
				{!isHideLabel && <Grid item xs={xsLabel} sx={itemSx ?? horizontalFormControlGridLeftStyle}>
					<StyledFormLabel aria-describedby={name} hidden={isHidden} error={formState?.errors[name] !== undefined} sx={textSx ?? {
            ...requiredLabelStyle,
            textAlign: textAlign ?? "right"
          }}>
						{separateLabel}
					</StyledFormLabel>
				</Grid>}
				<Grid container item xs={xsInput ?? (xsLabel === 12 ? 12 : 12 - xsLabel)} sx={{
          position: "relative"
        }} data-sentry-element="Grid" data-sentry-source-file="NewSeparateLabelDatePickerFormControl.tsx">
					<LocalizationProvider dateAdapter={AdapterDayjs} data-sentry-element="LocalizationProvider" data-sentry-source-file="NewSeparateLabelDatePickerFormControl.tsx">
						<DemoContainer components={["DatePicker"]} sx={{
              width: "100%"
            }} data-sentry-element="DemoContainer" data-sentry-source-file="NewSeparateLabelDatePickerFormControl.tsx">
							<Box onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} sx={{
                width: "100%"
              }} key={`${childKey}-form-control-box`} data-sentry-element="Box" data-sentry-source-file="NewSeparateLabelDatePickerFormControl.tsx">
								<DatePicker name={name} label={label} format={formatDate} openTo={openTo} views={datePickerViews} value={handleValue(dateValue)} onChange={newValue => {
                  if (newValue?.isValid()) {
                    onChange(newValue.format("YYYY-MM-DD"));
                  } else {
                    onChange(newValue?.toString());
                  }
                }} slots={{
                  calendarHeader: isHideHeader ? () => <div style={{
                    height: "48px",
                    backgroundColor: "transparent"
                  }} /> : undefined
                }} slotProps={{
                  field: {
                    clearable: true,
                    onClear: () => setCleared(true)
                  },
                  textField: {
                    required: isRequired,
                    inputRef: inputRef,
                    error: formState?.errors[name] !== undefined
                  },
                  popper: {
                    sx: {
                      zIndex: zIndex ?? 99999
                    }
                  }
                }} disabled={isDisabled} sx={{
                  width: "100%"
                }} data-sentry-element="DatePicker" data-sentry-source-file="NewSeparateLabelDatePickerFormControl.tsx" />
							</Box>
						</DemoContainer>
					</LocalizationProvider>
				</Grid>
			</Grid>
			{formState && <FieldError key={`${childKey}-form-control-error`} formState={formState} name={name} textAlign={textErrorAlign} />}
		</FormControl>
	</Grid>;
}
;
export default memo(NewSeparateLabelDatePickerFormControl);